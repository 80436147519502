<template>
  <div>
    <h1 class="h2 indexPanel-title">忘記密碼</h1>
    <Form class="form" @submit="onSubmit" :validation-schema="schema" v-if="!isFinish">
      <FormInput field-name="username" field-label="帳號(公司統一編號/個人身份證字號)" />
      <FormInput field-name="email" field-label="Email" />
      <CaptchaInput :captcha-value="captcha?.value" @refresh-captcha="refreshCaptch"/>
      <div class="btn-group btn-group-vertical">
        <button class="btn btn-primary btn-full">送出</button>
        <RouterLink to="/login" class="btn btn-secondary btn-full">返回</RouterLink>
      </div>
    </Form>
    <div v-else>
      <p>重設信件已寄送，請至您填寫的信箱中確認(信件有效期間為1小時)</p>
      <div class="btn-group btn-group-vertical">
        <RouterLink to="/login" class="btn btn-secondary btn-full">返回</RouterLink>
      </div>
    </div>
  </div>
</template>
<script>
import { Form } from 'vee-validate';
import { useLoadingScreen } from '@/store/index';
import api from '@/api/api';
import { forgotPassword as schema } from '@/utils/schema';
import FormInput from '@/components/Input/FormInput.vue';
import CaptchaInput from '@/components/Input/CaptchaInput.vue';
import { mapActions } from 'pinia';

export default {
  components: {
    Form,
    FormInput,
    CaptchaInput,
  },
  data() {
    return {
      schema,
      captcha: null,
      isFinish: false,
    };
  },
  methods: {
    async onSubmit(formData) {
      this.setLoading(true);
      const captchaValid = await api.checkCaptcha({ key: this.captcha.key, value: formData.captcha });
      if (!captchaValid) {
        alert('驗證碼錯誤，請重新輸入');
        this.refreshCaptch();
        this.setLoading(false);
        return;
      }

      const isPassed = await api.forgotPasswordMailSend(formData);
      if (isPassed) {
        this.isFinish = true;
      } else {
        alert('查無會員資料');
        this.refreshCaptch();
      }
      this.setLoading(false);
    },
    refreshCaptch() {
      this.captcha = null;
      api.getCaptcha().then((data) => {
        this.captcha = data;
      });
    },
    ...mapActions(useLoadingScreen, ['setLoading']),
  },
  created() {
    this.refreshCaptch();
  },
};
</script>
